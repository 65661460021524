<template>
  <li @click="showVideoBtn">
    <div
      class="video-cover"
      :style="{
        'background-image': 'url(' + videoImg + ')',
      }"
    >
      <img src="@/assets/img/play.svg" alt="" />
    </div>
    <h3>{{ tit }}</h3>
    <p>{{ txt }}</p>
  </li>
</template>
<script>
export default {
  name: "VideoItem",
  props: ["id", "ytUrl", "ipcfUrl", "imageUrl", "tit", "txt"],
  methods: {
    //=== 點擊影片顯示 ===
    showVideoBtn() {
      //== 傳送項目 ==
      this.$bus.$emit("sendVideo", this.id);
    },
  },
  computed: {
    videoImg() {
      if (this.ytUrl) {
        return "http://img.youtube.com/vi/" + this.ytUrl + "/sddefault.jpg";
      } else {
        return this.imageUrl;
      }
    },
  },
};
</script>