<template>
  <div class="page-footer">
    <div class="footer-social">
      <a href="#" target="target">
        <img src="@/assets/img/icon_fb_outline.svg" alt="" />
      </a>
      <!-- <a href="#" target="target">
        <img src="@/assets/img/icon_ig_outline.svg" alt="" />
      </a>
      <a href="#" target="target">
        <img src="@/assets/img/icon_tw_outline.svg" alt="" />
      </a> -->
      <a href="#" target="target">
        <img src="@/assets/img/icon_youtube_outline.svg" alt="" />
      </a>
      <!-- <a href="#" target="target">
        <img src="@/assets/img/icon_g_plus_outline.svg" alt="" />
      </a> -->
    </div>
    <div class="footer-info">
      <div class="link-group">
        <a href="#" target="target">財團法人原住民族文化事業基金會</a>
        <a href="#" target="target">原住民族文化委員會</a>
        <a href="#" target="target">原住民族電視台節目版權目錄</a>
        <a href="#" target="target">購物商城</a>
      </div>
      <div class="link-group">
        <a href="#" target="target">WITBN 世界原住民廣電聯盟</a>
        <a href="#" target="target">原住民族委員會</a>
        <a href="#" target="target">原住民族族語線上詞典</a>
        <a href="#" target="target">原住民族語言能力認證測驗</a>
      </div>
    </div>
    <div class="coyright-txt">
      財團法人原住民族文化事業基金會 版權所有 Copyright © 2020 Indigenous
      Peoples Cultural Foundation All Rights Reserved.<br />
      電話：(02)2788-1600 傳真：(02)2788-1500 地址：台北市南港區重陽路 120 號 5
      樓
    </div>
  </div>
</template>
<script>
export default {
  name: "PageFooter",
};
</script>
