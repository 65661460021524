import axios from "axios";
import router from '../router'
// axios.defaults.headers.post["X-Requested-With"] = "XMLHttpRequest";
var apiBase = process.env.VUE_APP_APIDOMAIN;

const apiProxy = axios.create({
    // baseURL: 'http://3.115.99.119:5000/api/',
    baseURL: apiBase,
    //=== 開發環境使用 ===
    // baseURL: '/api', //設定一般路徑都走至/api ， vue.config.js 設定 proxy 走跨域請求
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
    },
    //=== 同意cookie ===
    withCredentials: true,
    //=== 跨域 ===
    // crossDomain: true,
    // transformResponse: [function (data) {
    //     // 对 data 进行任意转换处理
    //     console.log('這看看');
    //     console.log(data);
    //     return data;
    // }],
});
//攔截器 - 把回傳的資料先攔截下來處理，會再回傳
apiProxy.interceptors.response.use(function (response) {
    // console.log(user_token);
    // Do something with response data
    // console.log(response);
    return response;
}, function (error) {
    // Do something with response error
    console.log('錯誤');
    console.log(error.request.response);
    console.log(JSON.parse(error.request.response).message);
    console.log(error.request.status);
    if (error.request.status == '401') {
        console.log('尚未登入，回首頁');
        alert("已登出或尚未登入，請重新登入。");
        //== 要清掉登入者資訊 ==
        // localStorage.removeItem("accInfo");
        router.push("/").catch((err) => {
            err;
        });
    } else if (error.request.status == '400' && JSON.parse(error.request.response).message == "No permission") {
        console.log('無使用權限，回首頁');
        //== 使用到無權限項目，請重新登入 ==
        // apiLogout()
        //     .then((result) => {
        //         // console.log(result.data);
        //         if (result.data.message == "Success") {
        //             alert("無此使用權限，請重新登入。");
        //             console.log('無使用權限，登出');
        //             //== 要清掉登入者資訊 ==
        //             localStorage.removeItem("accInfo");
        //             router.push("/").catch((err) => {
        //                 err;
        //             });
        //         }
        //         //   context.commit("SETLIST", result.data);
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //     });
        //== 要清掉登入者資訊 ==
        // localStorage.removeItem("accInfo");
        // router.push("/");
    }
    return Promise.reject(error);
});
//=== token ===
export const apiToken = () => apiProxy.get('/token');

//== 內頁 ==
export const apiAreaInfo = (data) => apiProxy.post('/front/subcategory/info', data);
//== 影片 ==
export const apiVideoList = (data) => apiProxy.post('/front/video/list', data);
//== 搜尋 ==
export const apiSearch = (data) => apiProxy.post('/front/search', data);